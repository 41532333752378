.industries-section {
  background: linear-gradient(#000e23, #000e23);
  height: 100%;
  .text-center {
    font-weight: 600;
  }
  .text-center1 {
    font-weight: 600;
  }

  .inform {
    animation: 15s floatingrev linear infinite;
  }
  @keyframes floatingrev {
    0% {
      transform: translateY(30px);
    }
    50% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(30px);
    }
  }
  @keyframes floating {
    0% {
      transform: translateY(-30px);
    }

    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-30px);
    }
  }
  .Automobile {
    animation: 15s floating linear infinite;
  }
  .Construction {
    animation: 15s floatingrev linear infinite;
  }
  .Engineering {
    animation: 15s floating linear infinite;
  }
  .Finance {
    animation: 15s floatingrev linear infinite;
  }
  .Entertainment {
    animation: 15s floating linear infinite;
  }
  .Insurance {
    animation: 15s floatingrev linear infinite;
  }
  .Manufacturing {
    animation: 15s floating linear infinite;
  }
  .Health {
    animation: 15s floatingrev linear infinite;
  }
  .Logistics {
    animation: 15s floating linear infinite;
  }
  .Retail {
    animation: 15s floatingrev linear infinite;
  }
  .mm-iconbox__icon-wrapper {
    width: auto;
    border-radius: 20px;
    display: inline-block;
    box-shadow: 0px 0px 30px 0px #ffffff40;
    padding: 10px;
  }
  img.img-responsive.icon-img {
    width: 80px;

    box-shadow: none;
    padding: 5px;
    height: 70px;
    border: none;
    border-radius: 0;
    object-fit: contain;
  }

  .industries-served {
    padding: 3em 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .industries .col-md-2 {
    background: #fff;
    border-radius: 15px;
    max-width: 13%;
    margin: 20px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1098039216);
    transform: scale(0.9);
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform-origin: center;
  }
  .industries-served {
    h2 {
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 50px;
      color: #fff;
      font-weight: 400;
    }
    h1 {
      margin: 30px;
      font-weight: 600;
    }
    p {
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 200;
    }
    .industries-served img.img-responsive.icon-img {
      width: 80px;
      margin: 0;
      box-shadow: none;
      padding: 20px;
      height: 80px;
      border: none;
      border-radius: 0;
      object-fit: contain;
    }
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #231557;
  }

  .animate-charcter {
    background-image: linear-gradient(
      -25deg,
      #231557 0%,
      #200e84 29%,
      rgb(194, 226, 169) 67%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 50px;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  @media screen and (max-width: 600px) {
    .industries-served {
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 16px;
      }
    }

    span {
      font-size: 9px !important;
    }

    .mm-iconbox__icon-wrapper {
      box-shadow: none;
      padding: 0px;
    }
    img.img-responsive.icon-img {
      height: 40px;
    }
    .inform {
      animation: none;
    }
    .Automobile {
      animation: none;
    }
    .Construction {
      animation: none;
    }
    .Engineering {
      animation: none;
    }
    .Finance {
      animation: none;
    }
    .Insurance {
      animation: none;
    }
    .Manufacturing {
      animation: none;
    }
    .Health {
      animation: none;
    }
    .Logistics {
      animation: none;
    }
    .Retail {
      animation: none;
    }
    .Entertainment{
      animation: none;
    }
    .col-md-2{
      max-width: 26% !important;
    }
    // .Entertainment {
    //   display: none !important;
    // }
  }
  @media screen and (max-width: 375px) {
    .industries-section {
      padding: 0px;
    }
    .col-md-2 {
      margin: 12px !important;
    }
    .inform {
      animation: none;
    }
    .Automobile {
      animation: none;
    }
    .Construction {
      animation: none;
    }
    .Engineering {
      animation: none;
    }
    .Finance {
      animation: none;
    }
    .Insurance {
      animation: none;
    }
    .Manufacturing {
      animation: none;
    }
    .Health {
      animation: none;
    }
    .Logistics {
      animation: none;
    }
    .Retail {
      animation: none;
    }
    .Entertainment{
      animation: none;
    }
    img.img-responsive.icon-img {
      height: 30px;
    }
    span {
      font-size: 7px !important;
    }
  }
  @media screen and (max-width: 395px) {
    .industries-served{
      p{
        font-size: 14px !important;
      }
    }
    .Entertainment{
      animation: none;
    }
    .inform {
      animation: none;
    }
    .Automobile {
      animation: none;
    }
    .Construction {
      animation: none;
    }
    .Engineering {
      animation: none;
    }
    .Finance {
      animation: none;
    }
    .Insurance {
      animation: none;
    }
    .Manufacturing {
      animation: none;
    }
    .Health {
      animation: none;
    }
    .Logistics {
      animation: none;
    }
    .Retail {
      animation: none;
    }
    .col-md-2 {
      max-width: 22% !important;
    }
    .mm-iconbox__icon-wrapper  {
      width: 36px !important;
    }
    span{
      font-size: 7px !important;
    }
    .row{
      padding: 0px;
      margin-bottom: 0px;
    }
  }
}
