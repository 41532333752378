.img .contact1{
    color: #3b0ede;
    text-align: center;
    font-family: "poppins";
    font-size: 40px;
}

.img .contactus{
    font-family: "poppins";
    color: rgb(14, 14, 14);
    text-align: center;
}

.img .view5{
    padding-top: 70px;
    background: #fff;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.37);
    height: 500px;
    width: 700px;
    margin-left: 200px;
}

.img .navbar .navbar-nav .nav-item .nav-link {
    color: rgb(255, 255, 255);
}

.img .basic label{
    color: #000000;
    font-family: "poppins" !important;
}

.img .box label{
    color: #0f0f0f;
    font-family: "poppins" !important;
    /* padding-top: 20px; */
    padding-left: 155px;
}

.img .css-ahj2mt-MuiTypography-root{
    font-family: "poppins" !important;
    /* padding-left: 155px; */
}

.img .box2 button{
    font-family: "poppins" !important;
    margin-top: 25px;
    width: 400px;
    margin-left: 20px;
}

.formlogin .css-v4u5dn-MuiInputBase-root-MuiInput-root:after{
    color: rgb(3, 3, 3);
}

.formlogin .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: rgb(7, 7, 7) !important;
}

.img .box2{
    /* padding-left: 0px; */
    font-family: "poppins" !important;
}

.img .body{
    color: rgb(50, 8, 241);
    /* padding-right: 150px; */
}

.basic #standard-basic{
    color: rgb(0, 0, 0) !important;
    width: 390px;
}

.img .contact1 h1{
    color: #000000;
}
.img{
    background-color:   #ffffff !important;
    height: 800px;
    padding-top: 150px;
}
.img .css-v4u5dn-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 1px solid rgba(12, 12, 12, 0.959) !important;
    /* width: 250px; */
}
.img .check p{
    font-size: 12px;
    /* padding-left: 180px; */
    font-family: "poppins";
    padding-top: 30px;
    margin-left: 20px;
    color: #000000;
}
.img .check a{
    font-size: 12px;
    color: #070707;
    text-decoration: underline;
    font-family: "poppins";
}
.img .down {
    font-size: 16px;
    font-family: "poppins";
    padding-left: 380px;
    color: rgb(0, 0, 0);
}

.img .navbar .navbar-nav .nav-item .nav-link{
    color: rgb(2, 2, 2);
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    width: 372px;
}
.img-pin a{
    color: #000000;
    padding-right: 100px;
    
}