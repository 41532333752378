.product-showcase{
    background: radial-gradient(#2b4363 10%, #000e23 70%);
    height: 100%;
    position: relative;

    video{
        width:100%;
    }

    [type='button']{
        border: 2px solid #ffffff;
        color: #fff;
        padding: 15px 25px;
        line-height: 1;
        margin-top: 10px;
      }

    .title{
        color: #fff;
        font-weight: 400;
        font-size: 50px;
        text-align: center;
        letter-spacing: 1px;
        line-height: 40px;
    }

    .subtitle{
        color: #fff;
        font-weight: 200;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        line-height: 40px;
    }
    
    p{
        color: #fcfcfc;
        font-weight: 200;
        letter-spacing: 0.8px;
        font-size: 1px;
    }
  
    .feature{
        margin-bottom: 50px;
        display: flex;
        .number {
            display: inline;
            float: right;
            color: rgba($color: #000000, $alpha: 0);
            font-size: 30px;
            // line-height: 1.16em;
            margin: 0;
            text-align: right;
            -webkit-text-stroke: 1px #ffffff;
        }
        .title {
            margin: 10px auto;
            font-weight: 200;
            color: #fff;
            font-size: 16px;
            line-height: 1.17em;
            margin: 25px 0;
            // -ms-word-wrap: break-word;
            // word-wrap: break-word;
            
        }
        
        &.right{
            .number {
                float: left;
            }
            
        }
    }  
    .cls-1{
        fill: white;
        border: solid;
        border-color: #fff;
  animation: pulse 3s
   infinite;
    }
    @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        75% {
          transform: scale(1.1);
          opacity: 0.5;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
      @media screen and (max-width: 600px) {
        [type='button']{
            padding: 10px 15px;
        }
        
        .feature{
            .title {
                margin: 10px auto !important;
                font-size: 14px !important;
            }
        }
        
      }
      
}