.Projects{
    // width: 80%;
    // max-width: 1200px;
    margin: 0 auto;
    padding: 7rem 0;
    text-align: center;
    background: radial-gradient(#2b4363 10%, #000e23 70%);
}

.Projects h1{
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
}

.Projects{
    overflow: hidden;
}

.slick-dots li button:before{
    color: #fff !important;
}

.Projects p{
    width: 28rem;
    margin: 0 auto;
    padding: 1rem 0;
    font-size: 14px;
    color: #fcfcfc;
}

// .Projects .slide{
    
// }

.slick-slider{
    padding: 0.5rem 0;
}

.slick-list{
    margin: 0 -150px !important;
}

.Project{
    height: auto;
    background-color: #4e5156;
    margin: 0 3rem;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.Project img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.5s;
}

.Project .disc{
    position: absolute;
    right: 0;
    left: 0;
    bottom: -10rem;
    text-align: left;
    padding: 0.5rem;
    transition: 0.5s;
}

.Project h1{
    font-size: 1rem;
    color: white;
}

.Project p{
    width: 90%;
    font-size: 0.8rem;
    color: white;
}

.Project:hover > img{
    transform: scale(1.1);
}

.Project:hover > .disc{
    bottom: 0;
}

.footers{
   background: radial-gradient(#2b4363 10%, #000e23 70%); 
}