.hrsolu{
    background: #f5f5f3;
    padding: 70px;
}

.hrsol{
    background: white;
    padding: 70px;
}

.hrrow1 .hrsol{
    background: #f5f5f3;
    padding: 70px;
}

.hrrow1 .hrsolu{
    background: white;
    padding: 70px;
}


.hrrow h1{
    color: black;
}

.hrrow2 h1{
    color: black;
}

.hrrow1 h1{
    color: black;
}

.hrrow1 .hrsol::after{
    background-image: url("../HrSolution/icons8-recruitment-100\ \(2\).png");
}

.hrrow2 .hrsol::after{
    background-image: url("../HrSolution/icons8-executive-100.png");
}

.hrrow3 .hrsol::after{
    background-image: url("../HrSolution/icons8-outsourcing-100.png");
}

.hrrow4 .hrsol::after{
    background-image: url("../HrSolution/icons8-compliance.png");
}

.hrsol::after{
    position: absolute;
    background-image: url("../HrSolution/icons8-human-resources-100.png");
    content: " ";
    top: 160px;
    right: -60px;
    height: 120px;
    width: 120px;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* border: 2px solid black; */
    background-color: #2b4363;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
}