* {
    margin: 0;
    padding: 0;
  }
  .default-container,
  .component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .default-container {
    gap: 20px;
  }
  .component-container {
    margin-top: 30px;
    gap: 20px;
  }
  button {
    padding: 5px 10px;
    font-size: 16px;
  }
  .button{
    position: absolute;
    right: 30px;
  }
ol li{
  color: #878787;
  padding: 10px;
}
.contributors{
  color: black;
}