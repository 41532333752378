.Contact{
    color: rgb(253, 253, 253);
    text-align: center;
    padding-top: 280px;
    font-family: 'Roboto Condensed';
    font-weight: bolder;
    padding-right: 750px;
    margin-bottom: 70px;
    /* background: url('../banner/contact-banner-1.jpg') no-repeat; */
    width: 100%;
    background-size: cover;
    height: 80vh;
    font-size: 300%;
    margin-top: 90px;
}
.Contact1 {
    text-align: center;
    padding-bottom: 10px;
    justify-content: center;
    font-weight: bold;
    color:  #0D47A1;
    font-family: 'Roboto Condensed';
    font-size: 45px;
    margin-top: 50px;
}
.row-1 p{
    /* text-align: center; */
    margin-top: 0px;
    padding: 0 80px 0 80px;
    font-size: 20px;
    font-family: 'Roboto Condensed';
}
.body {
  justify-content: center;
  padding-bottom: 50px;
  margin-left: 60px;
  font-size: 1.5rem;
}
#standard-basic{
    font-size: 1.5rem !important;
}
.body2{
    text-align: center;
    margin-right: 38px;
    justify-content: center;
    font-size: 3.5rem !important;
}
.var1{
    color: black;
}
.var2{
    text-align: center;
    /* padding-top: 10px; */
    font-family: 'Roboto Condensed';
    color: #1f252f;
    margin-top: 40px;
    margin-right: 60px;
}

.parcon{
    text-align: left;
    padding-top: 25px;
}
#heading h2{
    font-weight: bolder;
    font-family: 'poppins';
    margin-right: 20px;
    margin-top: 60px;
    color: #050505;
    font-size: 45px;
}
.head2{
    font-weight: bolder;
    font-family: 'poppins';
    margin-right: 20px;
    margin-top: 110px;
    color: #050505;
    font-size: 45px;
}
.p1{
    font-family: bolder;
    color: black;
    font-size: 20px;
    font-family: 'poppins';
    padding-right: 70px;
}
.body{
    margin-left: 0px !important;
}

.par{
    /* padding-left: 55px; */
    padding-top: 25px;
}
.par2{
    /* padding-left: 55px; */
    padding-top: 25px;
}
.fade1{
    color: #00409c;
}
.p3{
    padding-right: 70px;
    color: #050505;
    font-family: bolder;
}

.icons{
    color: #00409c;
}
.par3{
    /* padding-left: 55px; */
    padding-top: 25px;
}
.group{
    /* padding-left: 55px; */
    padding-top: 25px;
}
/* .banner{
    width: 100%;
    height: 100%;
} */
/* .banner{
    margin-top: 70px;
} */
/* .banner1{
    padding-right: 30px;
    margin-top: 70px;
} */
.map1{
    margin-top: 100px;
    width: 500px;
    height: 300px;
    margin-bottom: 10px;
    padding-right: 100px;
}
.map2{
    margin-top: 100px;
    width: 500px;
    height: 300px;
    margin-bottom: 10px;
    padding-left: 70px;
}
.len1{
    margin-right: 80px;
}
.len2{
    margin-left: 80px;
}
@media screen and (max-width:600px) {

    .len1{
        margin-right: 0px !important;
        margin-left: 80px;
    }
    .map1{
padding-right: 0px;
    }
    .map2{
        padding-left: 0px;
    }
}
@media screen and (max-width:395px) {
    .body{
        padding-bottom: 0px !important;
    }
    .map1{
        width: 376px !important;
    }
    .map2{
        width: 376px !important;
    }
}