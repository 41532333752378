 #canvas{
    background-color: transparent;
    height: 85vh !important;
 } 

.wrapper h1{
    color: white !important;
}

 .relate{
    position: relative;
} 

 .abso{
    position: absolute;
    top: 0;
    z-index: 1;
    height: 85vh !important;
    width: 100% !important;

}  