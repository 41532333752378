.leftside .images{
    width: 25% !important;
}

.productbackground{
    background: radial-gradient(#2b4363 10%, #000e23 70%) !important;
}

.wave2{
    width: 50%;
    margin-left: 100px;
}

.wave1{
    width: 50%;
    margin-left: 100px;
}

.wave3{
    width: 50%;
    margin-left: 100px;
}

.leftside h1 {
    color: #fff;
    font-size:18px;
    font-weight: 400;
    margin-bottom: 18px;
}

.leftside p {
    color: #fcfcfc;
    font-size: 16px;
    font-weight: 200;
}
@media screen and (max-width:600px) {
    .emploiFeatures{
        width: 50% !important;
    }
}
@media screen and (max-width:395px) {
    .emploiFeatures{
        width: 100% !important;
    }
   .jobposting{
    display: flex;
    flex-direction: column-reverse;
   }
    
  }  