@media screen and (max-width: 991px){
  .navbar-nav .dropdown-menu {
    position: static !important;
    float: none;
  }
  @media screen and (max-width: 600px){
    .navbar-nav .dropdown-menu {
      position: static !important;
      float: none;
    }
}
}