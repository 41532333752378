@media screen and (max-width:395px) {
    .navbar-toggler{
        outline: auto !important;
    }
    .navbar-toggler-bar{
        height: 2px !important;
        background: black !important;
    }
    .nav-link{
        color: white !important;
    }
}