.IT-Solution{
  body {
    background-color: #fff !important;
  }


    .staff{
        background-color: #f5f5f3;
        padding: 100px;
      }
      .doc{
     display: flex;
     justify-content: center;
     align-items: center;
      margin-top: 30px;
      }
     @media screen and (max-width:395px) {
      .development{
        display: flex;
        flex-direction: column-reverse;
        
      }
      .support{
        display: flex;
        flex-direction: column-reverse;
      }
     }
}
