@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.footer_section{
  .social-icon, .menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
  } 

  .social-icon__item,
  .menu__item {
    list-style: none;
  }

  .social-icon__link {
    font-size: 2rem;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
  }

  .social-icon__link:hover {
    transform: translateY(-10px);
    color:  #8cc63f;;
  }

  .menu__link {
    font-size: 1.2rem;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75;
    font-weight: 300;
  }

  .menu__link:hover {
    opacity: 1;
    color:  #8cc63f;;
  }

  .footer p {
    color: #fff;
    margin: 15px 0 10px 0;
    font-size: 1rem;
    font-weight: 300;
  } 

  .wave{
    height: 10vh !important;
  }

  .footer {
    height: 30vh;
    border-color: #1277b0;
    border-top: none;
    padding: 0px;
    background: #1277b0;
    margin-top: -5px;
  }
  .par{
    /* margin-left: 580px; */
    text-align: center;
  }
}
.Footer-form{
  padding: 20px;
  .buttons{
    background: #021127;
    border-radius: 50px;
    padding: 5px;
    margin-top: 10px
  }

 
}
// .footer-content {
//   display: flex;
//   justify-content: end;
//   align-content: center;
//   flex-wrap: wrap;
// }
.Ready{
  font-size: 50px;
  color: #8cc63f;
}
.Talk{
  font-size: 30px;
}
.form-control{
  border: none;
  border-bottom: solid black;
  border-radius: 0px;
  width:450px;
}
@media screen and (max-width:600px) {
  .Ready{
    font-size: 30px;
  }
  .talk{
    font-size: 20px;
  }
//   .footer-newsletter{
//   height: 0;
//  }
 .p-5{
  padding: 10px !important;
 }
@media screen and (max-width:395px) {
  .menu__link{
    font-size: 15px !important;
  }
  
  
}
}