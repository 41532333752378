.animated-loader{
    div{
        width: 25%;
        height: 105vh;
        position: absolute;
        z-index: 100;
        top: 0;
        background-color: #8cc63f;
        animation: slideUp 0.7s ease-out forwards;
    }

    .animated-box-1 {
        left: 0;
        animation-delay: 0.4s;
    }
    .animated-box-2 {
        left: 25%;
        animation-delay: 0.7s;
    }
    .animated-box-3 {
        left: 50%;
        animation-delay: 1s;
    }
    .animated-box-4 {
        left: 75%;
        animation-delay: 1.3s;
    }
}

@keyframes slideUp {
    from {
        top: 0;
    } 
    to {
        top: -105vh;
    }
}