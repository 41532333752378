@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.SubHeader {
    margin-top: 6rem;

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: 'Poppins', sans-serif;

        &_heading {
            color: #171717;
            font-size: 50px;
            font-weight: 400;
            letter-spacing: 0.5px;
        }

        &_subHeading {
            font-size: 16px;
            line-height: 1.9rem;
            font-weight: 400;
            color: #575757;
        }
    }

    &-image {
        // background-image: url(https://valiance.qodeinteractive.com/wp-content/uploads/2021/06/h2-parallax-img.jpg);
        background-repeat:no-repeat;
        background-Position: top;
        background-Size: cover;
        height: 600px;
        width: 100%;
         z-index: 1 !important;
        opacity: 0.4 !important;
        &Section {
            position: relative;
        }
    }

    &-overlayImage {
        position: absolute;
        top: -44px;
        left: 2rem;
        text-align: center;
        width: 26rem;
        height: 26rem;
      
        &_centered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-firstText {
                font-size: 11px;
                line-height: 1.8em;
                font-weight: 500;
                letter-spacing: .35em;
                text-transform: uppercase;
                color: #8b8b8b
            }
            &-secondText {
                font-weight: 400;
                color: #171717;
                font-size: 36px;
                line-height: 1.22em;
            }
        }
    }
    .SubHeader-content_subHeading{
        font-size: 20px;
        font-weight: 400;
        text-align: center !important;
    }
    @media screen and (max-width:395px) {
        .SubHeader-content_subHeading{
            font-size: 14px !important;
        }
        // .bg{
        //     display: none;
        // }
        // .SubHeader-image{
        //     background-size: contain;
        // }
    }
}