.why-krishpar {
  position: relative;
  background: radial-gradient(#000e23, #000e23);
  height: 100%;
  
  &::before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    margin: 10px;
    background-size: 500px;
    background-size: contain;
    z-index: -1;
  }
  .content {
    color: #004e4c;
    height: 75vh;

    // .content__left,
    // .content__right {
    //   width: 50%;
    // }

    .content__left {
      font-family: inherit;
      .content__left--main-card {
        position: relative;
        width: 26em;
        text-align: center;
        margin: 0 auto;

        .desktop-view-bg-img {
          .pin1,
          .pin2,
          .line-img {
            position: absolute;
          }

          .pin1 {
            right: -10em;
            top: 17.5em;
          }

          .pin2 {
            left: -3.375em;
            top: 6.5em;
          }

          .line-img {
            right: -17.5em;
            top: 24.5625em;
          }
        }

        .mobile-view-bg-img {
          display: none;
        }

        .main-img {
          width: 100%;
          border-radius: 1.5em;
          margin-bottom: 1em;
        }

        .text-box {
          text-align: left;

          .title {
            height: 1.5em;
            font-size: 1.25em;
            font-weight: 700;
          }

          .place {
            font-size: 1em;
            font-weight: 400;
          }
        }

        [class*="content__left--card"] {
          position: absolute;
          background: #fff;
          border-radius: 50px;
          text-align: center;
          width: 3rem;
          height: 3rem;
          display: flex;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            border-radius: 0.625em;
            margin-bottom: 0;
          }

          .title {
            height: auto;
            font-size: 1.4em;
            color: #00409c;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0px;
            text-align: center;
          }

          .place {
            height: 1.25em;
            font-weight: 400;
            font-size: 0.8em;
          }
        }

        .content__left--card-1 {
          left: 20.25em;
          top: 2.5em;
        }

        .content__left--card-2 {
          left: 0.625em;
          top: 21.125em;
        }

        .content__left--card-3 {
          left: 23.625em;
          top: 21.625em;
        }
      }
    }
      

    .content__right {
      hr{
        width: 100%;
        border-color: #ffffff;
        margin:8px;
      }
      p{
        font-size: 18px;
        color: #ffffff;
        font-weight: 200;
        text-transform: capitalize;
        line-height: 3rem;
      }
      .top {
        margin-left: 3.8em;
        .content__right--subtitle {
          font-size: 1.875em;
          font-weight: 400;
          line-height: 42px;
          margin-bottom: 0.5em;
        }

        .content__right--title {
          font-size: 6.125em;
          font-weight: 700;
          letter-spacing: -0.02em;
          line-height: 100%;
          span {
            color: #fda622;
          }
        }
      }

    }
  }

  // @media (max-width: 786px) {
  //   .content {
  //     margin-top: 9.2em;
  //     .content__left,
  //     .content__right {
  //       width: 100%;
  //       clear: both;
  //     }

  //     .content__right {
  //       .content__right--title {
  //         font-style: normal;
  //         font-weight: bold;
  //         font-size: 54px;
  //         line-height: 100%;
  //       }
  //       .content__right--desc{
  //         font-size: 6rem !important;
  //       }
  //     }
  //     .content__left {
  //       .content__left--main-card {
  //         width: 100%;
  //         margin-top: 7.7em;

  //         .desktop-view-bg-img {
  //           display: none;
  //         }

  //         .mobile-view-bg-img {
  //           display: block;
  //           position: absolute;
  //           bottom: -35em;
  //           left: 5.07em;
  //           padding-bottom: 5.07em;
  //         }

  //         & > .text-box {
  //           position: absolute;
  //           top: -3.815em;

  //           .title {
  //             height: 1.5em;
  //             font-size: 1.25em;
  //             font-weight: 700;
  //           }

  //           .place {
  //             font-size: 1em;
  //             font-weight: 400;
  //           }
  //         }

  //         [class*="content__left--card"] {
  //           background: white;
  //           padding: 5px;
  //           border-radius: 0.75em;

  //           img {
  //             border-radius: 0.625em;
  //           }

  //           .title {
  //             color: #333333;
  //           }
  //         }

  //         .content__left--card-1 {
  //           top: inherit;
  //           bottom: -3.5625em;
  //           left: 1.5em;
  //           width: 8.75em;
  //         }

  //         .content__left--card-2 {
  //           top: inherit;
  //           left: inherit;
  //           bottom: -15.5em;
  //           right: 0;
  //           width: 8.625em;
  //           z-index: 99;
  //         }

  //         .content__left--card-3 {
  //           left: 0;
  //           top: inherit;
  //           width: 9.0625em;
  //           bottom: -20em;
  //         }
  //       }
  //     }
  //   }
  // }
  
  .why{
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 50px;
    color: #fff;
    font-weight: 400;
  }
  .demo{
    color: #fff;
  }
 .demo1{
  color: #fff;
 }
 .demo2{
  color: #fff;
 }
 @media screen and (max-width: 600px) {
  .why{
     font-size: 25px !important;
   }
   .content__right{
    p{
      font-size: 16px;
     }
   } 
   .squares{
    display: none;
   }
   .square1{
   display: none;
   }
   .square2{
   display: none;
   }
   .square{
    display: none;
   }
   .square5{
   display: none;
   }
   .square6{
    display:none;
   }
   .square7{
    display: none;
   }
   .content{
    height: 100%;
   }
 }
 @media screen and (max-width: 375px) {
  .content__left--main-card{
    width: 18rem !important;
  }
 }
 @media screen and (max-width: 395px) {
  .content__left--main-card{
    width: 16rem !important;
    margin: 0 !important;
  }
  [class*="content__left--card"]{
    width: 2rem !important;
    height: 2rem !important;
  }
  .content__left--card-2 {
    top: 16.125em !important;
 }
 .content__left--card-1{
  left: 15.25em !important;
  top: 0.5em !important;
 }
 .content__left--card-3{
  left: 15.625em !important;
  top: 15.625em !important;
 }
 .content__right{
  p{
    font-size: 14px !important;
    line-height: 2rem !important;
  }
 }
 }}