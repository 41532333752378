.product-banner {
  background: radial-gradient(#000e23, #000e23);
  position: relative;
  overflow: hidden;
  height: 100vh;

  .bg-video{
    opacity: 0.5;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    object-fit: cover;
  }

  .title{
    color: #fff;
    white-space: normal;
    text-align: justify;
    line-height: 62px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 25px;
    

  }
  .heading{
    color: #ffff;
    text-align: justify;
    font-size: 50px;
    font-weight: 900;
    padding-left: 0px;
    
  }
  .product-banner-section-text{
   opacity: 1 !important;
  }
  .product-banner-section {
  //   margin: 40px auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    // opacity: 0.3;
   
    // background-position:center;
    // background-attachment: inherit;
    &>div{
      height: 100%;
    }

    &:before{
      content: none;
      background-size: cover;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
  
  .product-banner-section-wrapper {
    width: 100%;
    display: inline-block;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .product-banner-section-text {
    width: 100%;
    padding: 0px 150px;
  }
  
  .product-banner-section-pic {
    text-align: center;
    top: 100px;
    left: 40px;
    
    img{
        width: 100%;
        margin: 0 auto;
         height: 100%;
        // object-fit: contain;
    }
  }
  .product-banner-section-pic video{
    width: 100%;
    height: 90vh;
    // object-fit: cover;
  }
  
  .product-banner-section-map {
    display: none;
    width: 250px;
    top: 60px;
    left: 30px;
  }
  // .product-banner-section-map.map2{
  //   // width: 200px;
  //   // left: 50px;
  // }
  
  .product-banner-section-text-wrapper .banner-anim-elem {
    padding: 40px;
    display: flex !important;
    height: 100%;
    // position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }
  
  .product-banner-section-slide-wrapper .banner-anim-elem>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .product-banner-section-text-wrapper h1 {
    margin: 5px auto;
  }
  
  .product-banner-section-text-wrapper em {
    height: 2px;
    width: 60px;
    border-radius: 2px;
    display: block;
  }
  
  .product-banner-section-text-wrapper p {
    margin-top: 10px;
  }

  [type='button']{
    border: 2px solid #fff;
    color: #fff;
    padding: 15px 25px;
    line-height: 1;
    margin-top: 10px;
  }
  
  .fa{
    cursor: pointer;
    font-size: 40px;
    color: #fff;
    opacity: 1;
    bottom: 50px;
    position: absolute;
    left: 50px;
    right: 0;
    margin: 0 auto;
    font-size: 30px;
    z-index: 2;
  }
  .fa.fa-arrow-right{
    left: auto;
    right: 50px;
  }
  
  .product-banner-section-wrapper .arrow-left{
    left: 5px;
    z-index: 999;
  }
  
  .product-banner-section-wrapper .arrow-right{
    right: 5px;
  }
  // @media screen and (max-width: 414px) {
  //   .product-banner-section {
  //     transform: scale(.6) translateX(12px);
  //     transform-origin: left center;
  //   }
  // }
  // @media screen and (max-width: 375px) {
  //   .product-banner-section {
  //     transform: scale(.55) translateX(7px);
  //   }
  // }
  // @media screen and (max-width: 320px) {
  //   .product-banner-section {
  //     transform: scale(.46) translateX(12px);
  //   }
  // }
  @media screen and (max-width: 600px) {
    .product-banner-section{
      height: 600px;
    }
    .product-banner-section-text{
      h1{
        color: white;
        text-align: justify;
        font-size: 30px;
        font-weight: 900;
        padding-left: 0px;
        margin: 0px;
        padding: 0px;
        // margin-top: 90px;
      }
      .title{
      line-height: 30px;
        font-size: 16px !important;
      
    }
     
    }
     [type='button']{
      padding: 10px 15px;
    }
    .bg-video{
      height: 90%;
    }
     
  }

  @media screen and (max-width: 375px) {
    .product-banner-section-wrapper{
          height: 0px;
    }
    
    .product-banner-section-text{
      padding: 15px ;
      h1{
        color: white;
        text-align: justify;
        font-size: 20px;
        font-weight: 900;
        padding-left: 0px;
        margin: 0px;
        padding: 0px;
        // margin-top: 90px;
      }
      .title{
      line-height: 20px;
        font-size: 10px !important;
      
    }
     
    }
     [type='button']{
      padding: 5px 10px;
    }
    .bg-video{
      height: 0px;
    }
     
  }

  @media screen and (max-width:395px) {
    .product-banner-section-text{
      padding: 20px;
      h1{
        font-size: 22px;
      }
      title{
        font-size: 12px !important;
      }
    }
    
  }
 
  .banner-anim-elem[style^='display: block']{
    z-index: 1;
  }
  .banner-anim-thumb{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0px auto;
    text-align: center;
  }
  .banner-anim-thumb>span{
    display: inline-block;
    height: 10px;
    width: 10px;
    background: transparent;
    border: 2px solid #fff;
    margin-left: 10px;
    border-radius: 10px;
    transition: all 0.2s;
  }
  .banner-anim-thumb>span.active, .banner-anim-thumb>span:hover{
    cursor: pointer;
    background: #fff;
  }

  
}
