/* @font-face {
font-family: 'colfax-light';
src: url("../fonts/colfax-light.woff") format("woff"), url("../fonts/colfax-light.woff2") format("woff2");
font-weight: normal;
font-style: normal
}

@font-face {
font-family: 'colfax-medium';
src: url("../fonts/colfax-medium.woff") format("woff"), url("../fonts/colfax-medium.woff2") format("woff2");
font-weight: normal;
font-style: normal
}

@font-face {
font-family: 'colfax-bold';
src: url("../fonts/colfax-bold.woff") format("woff"), url("../fonts/colfax-bold.woff2") format("woff2");
font-weight: normal;
font-style: normal
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap');

body, body *{
    font-family: 'Poppins', sans-serif;
   
 
}

.navbar{
    z-index: 10 !important;;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #505050;
    font-weight: 300;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #000;
}

.navbar .navbar-nav .nav-item .nav-link{
    color: #505050;
    font-weight: 400;
    font-size: 16px;
}

.index-page .navbar .navbar-nav .nav-item .nav-link{
    color: #fff;
}
.loginbutton{
    border: 2px solid #505050;
    color: #505050;
    padding: 2px 16px;
    border-radius: 30px;
}

.index-page .loginbutton{
    border-color:#fff;
    color: #fff;
}
.fixed-top{
    position: absolute;
}

p{
    color:#878787;
    font-size: 16px;
    font-weight: 400;  
}
h3{
    color: #171717;
    font-weight: 400;
    font-size: 27px;
    line-height: 1.22em;
    word-wrap: break-word;
    /* text-align: center; */
}

.page-header .title{
    margin-top: 10px;
    margin-bottom: 0;
}
h4{
    color: #171717;
    font-weight: 900;
    font-weight: 42px;
    line-height: 1.22em;
    word-wrap: break-word  ;
}
.index-page .squares.square1{
    top: auto;
    bottom: 10% !important;
}

.index-page .squares.square2{
    top: 15% !important;
}

.content__left, .content__right{
    width: auto;
}

.pin {
    width: 30px;
}

.index-page .squares {
    background: #3358f4;
    background: linear-gradient(0deg, #73c79a 0%, #8cc63f 100%);
}

.scroll-container {
    width: auto;
    height: auto;
    position: relative;
    transform: rotate(0) translateX(0);
    transform-origin: none;
}

.navbar-brand>img{
    width: 200px;
}
.navbar.bg-info {
    background-color: #fff !important;
  }
  abtbanner{
    height: 500px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-bottom: 90px;
  }
  
  .addon-services{
      margin: -88px 26px 0px -20px;
      padding: 15px 10px 15px 10px;
      background-image: linear-gradient(120deg, #1642fd 0%, #2bf374 100%);
      border-radius: 10px 10px 10px 10px;
      display: flex;
      align-items: center;
      width: 50%;
      position: absolute;
      right: -29px;
      margin-top: -71px;
      padding-bottom: 45px;
  }
  

  .about-wrap-content h3 .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: greenyellow;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .krishpar{
    text-align: justify;
  }
  .krishpar1{
    text-align: justify;
  }
  .krishpar2{
    text-align: justify;
  }
 .about{
    margin-top: 50px;
    
  }
/* .footer-newsletter{
    height: 62vh;
} */
/* .wrapper{
    overflow: auto;
} */


  /* media queries */
  /* @media only screen and (max-width: 600px) {
    .product-banner{
        
    }
  } */