.industries-served-page{
  .row{
    &>div{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      height: 100%;
      min-height: 500px;

      .component-container{
        margin-top: 0 !important;
      }
    }
  }
  .in{
    background-color: #f5f5f3;
    padding: 50px;
  }
  .doc{
    height: 400px;
  object-fit: contain;
  }
  .it{
  }
  p{
    text-align: justify;
  }
  h3{
    text-align: initial;
   
  }
  @media screen and (max-width:395px) {
    .automobile{
      display: flex;
      flex-direction: column-reverse;
    }
    .construction{
      display: flex;
      flex-direction: column-reverse;
    }
    .healthcare{
      display: flex;
      flex-direction: column-reverse;
    }
    .logistics{
      display: flex;
      flex-direction: column-reverse;
    }
    .entertainment{
      display: flex;
      flex-direction: column-reverse;
    }
    .button{
      padding: 4px !important;
    }
    .row{
  &>div{
    min-height: 0px;
    flex-direction: row-reverse;
    justify-content:flex-start ;
  }
    }
    .navbar-nav{
      color: white !important;
    }
    .navbar-toggler{
      color: black !important;
    }
  }
  
}