.glass2{
    background-image: linear-gradient(#2E3192 → #1BFFFF);
    padding-top: 100px;
}
.register .head{
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: "poppins" !important;
    margin-top: 60px;
}
.register .line1{
    margin-left: 200px;
    font-family: "poppins" !important;
    color: rgb(0, 0, 0);
}
.register .line1 label{
    color: #050505;
    font-family: "poppins" !important;
}
.register .line2 label{
    color: #000000;
    font-family: "poppins" !important;
}
.register .line3 label{
    color: #030303;
    font-family: "poppins" !important;
}
.register .line4 label{
    color: #010101;
    font-family: "poppins" !important;
}
.register #standard-basic{
    color: rgb(0, 0, 0);
}
.register .css-v4u5dn-MuiInputBase-root-MuiInput-root:after{
    border-bottom: 2px solid #0b0b0b !important;
}
.register .view4{
    background: #fff;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.37);
    height: 550px !important;
    width: 130vh;
    margin-left: 130px;
    padding-top: 70px;
}
.register .line2{
    margin-right: 20px;
}
.register .line3{
    margin-left: 230px;
    margin-top: 20px;
}
.register .line4{
    margin-right: 20px;
    margin-top: 20px;
}
.register .line3{
    margin-left: 200px;
    margin-top: 20px;
}
.register .var{
    text-align: center;
    margin-top: 50px;
}
.register .glass{
    background-color:   rgb(255, 255, 255) !important;
    height: 900px;
}

.register .navbar .navbar-nav .nav-item .nav-link{
    color: rgb(3, 3, 3) !important;
}