@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500&display=swap');
#Aboutus{
background: radial-gradient(#0a1b33, #000e23);
* {
    font-family: 'Poppins', sans-serif;
}

.about {
    background: radial-gradient(#2b4363 10%, #000e23 70%) !important;
    margin-top: 0px !important;
}

.aboutuspara h1 {
    padding-top: 70px;
    color: #ffffff;
    font-weight: 400;
    font-size: 50px;
}

.aboutuspara p {
    color: #ffffff;
    font-weight: 200;
    font-size: 16px;
}

.aboutuspara p:last-child {
    padding-bottom: 70px;
}

.SubHeader-content_heading {
    margin-bottom: 30px !important;
}

.SubHeader-content p {
    margin-bottom: 10px !important;
}

.aboutimage img {
    padding: 20px;
}

.aboutimage {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

.aboutusimage{
    margin-top: 120px;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}

.aboutimages {
    margin-top: 80px;
}

/* abbbbbb */

section {
    display: flex;
    min-height: 40px !important;
}

.dir {
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -200%);
}

.txtBlock {
    flex-basis: 33%;
    padding: 60px;
}



.thumbs {
    flex-basis: 66%;
    font-size: 0;
    line-height: 0;
}

.thumb {
    display: inline-block;
    width: 46%;
    margin-top: 2%;
    margin-left: 2%;
}

.thumbImg {
    background: #ffffff;
    position: relative;
    display: block;
    width: 100%;
    height: 290px;
}
@media screen and (max-width:600px) {
    .aboutuspara p:last-child{
        padding-bottom: 0px;
    }
    .aboutimage{
        margin-top: 0px !important;
    }
    .aboutusimage{
        margin-top: 0px !important;
    }
}

}