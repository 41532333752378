body {
    background-color: #fff !important;
    overflow-x: hidden;
  }
.hrstaff{
  height: 100% !important;
  width: 80% !important;
}

    .staff{
        background-color: #f5f5f3;
        padding: 100px;
      }
     .document{
       padding: 0px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 25px;
      }
     }
     p{
      font-size: 16px;
       
     }
     .software{
        height: 50%;
        width:50%
     }
     .itstaff{
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: justify;
     }
     .ITstaffing{
       h1{
        color: black;
        text-align: center;
        margin: 0px;
        margin-left: 10px;
        margin-right: 10px;
       }
       
       order{
        text-align: center;
        display: inline-flex;
       }
     }
     .itservices{
      li{
        color: #878787;
        // padding-left: 60px;
        list-style-type: initial;
        margin-left: 30px;
        font-size: 16px;
        text-align: justify;
      }
     }
     .order{
      text-align: justify;
      li{
        color:#878787;
        list-style-type: initial;
        margin-left: 40px;
        font-size: 16px;
        text-align: justify;
      }
      p{
        margin-left: 15px;
        text-align: justify;
      }
     }
     