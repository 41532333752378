.d-flex{
    justify-content: space-around;
    background: #2b4363;
    /* padding: 20px; */
    margin-bottom: 0px;
}

.tab{
    font-size: 18px;
    cursor: pointer;
    font-weight: 200;
}

.heading{
    color: white;
    padding: 20px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 200;
}

.heading.active{
    /* color: red; */
    border-bottom: 2px solid white;
}

.contents{
    background: radial-gradient(#2b4363 10%, #000e23 70%);
}

.contentss{
    display: none;
}

.show-content{
    color: red !important;
}

.imagess{
    display: none;
}

.files{
    text-align: center;
    margin-top: 10px;
}

.file{
    color: #fff;
}

.list{
    margin-bottom: 0px;
    text-align: center;
}

.innertext{
    padding: 20px 0;
    font-size: 18px;
}

button{
    background: transparent;
    border: 2px solid white;
    color: white;
}

button:hover{
    background: white;
    color: black;
}

.resume{
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: sticky;
    top: 0;
}
.innertext h3{
    color: #fff;
}
hr{
    width: 100%;
    border-top: 1px solid #fff;
}
.innertext span{
    font-family: "poppins" !important;
    color: #878787 !important;
}
.resume p{
    text-align: center;
    color: white;
    font-size: 18px;
}

.btns{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 2px dashed white;
    height: 150px;
    width: 300px;
    cursor: pointer;
    border-radius: 5px;
}

label[class$="MuiFormLabel-root-MuiInputLabel-root"]{
    font-family: inherit !important;
}
.btn{
    font-family: "poppins" !important;
}
@media screen and (max-width:395px) {
    .resume{
        height: auto;
    }
}
/* .latestdate{
    h3{
        color: white !important;
        text-align: center !important;
    }
} */