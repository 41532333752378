body {
    background-color: #fff !important;
    overflow-x: hidden;
  }
.hrstaff{
  height: 100% !important;
  width: 80% !important;
}

    .staff{
        background-color: #f5f5f3;
        padding: 100px;
      }
     .document{
       padding: 0px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 25px;
      }
     }
     p{
      font-size: 16px;
       text-align: justify;
     }
     @media screen and (max-width:395px) {
      .Recruitment{
        display: flex;
        flex-direction: column-reverse;
      }
      .outsourcing{
        display: flex;
        flex-direction: column-reverse;
      }
     }
      
//     .banner-section {
//         position: relative;
//         background: #f0e6e0;
//     }
//     .no-js .owl-carousel {
//         display: block;
//     }
//     .owl-carousel.owl-loaded {
//         display: block;
//     }
//     .elementor *, .elementor :after, .elementor :before {
//         -webkit-box-sizing: border-box;
//         box-sizing: border-box;
//     }
//     .banner-carousel {
//         position: relative;
//     }
//     .owl-carousel {
//         display: none;
//         width: 100%;
//         -webkit-tap-highlight-color: transparent;
//         position: relative;
//         z-index: 1;
//     }
//     .owl-carousel .owl-stage-outer {
//         position: relative;
//         overflow: hidden;
//         -webkit-transform: translate3d(0px, 0px, 0px);
//     }
//     .owl-carousel .owl-stage {
//         position: relative;
//         -ms-touch-action: pan-Y;
//     }
//     .owl-carousel .owl-item {
//         position: relative;
//         min-height: 1px;
//         float: left;
//         -webkit-backface-visibility: hidden;
//         -webkit-tap-highlight-color: transparent;
//         -webkit-touch-callout: none;
//         -webkit-user-select: none;
//         -moz-user-select: none;
//         -ms-user-select: none;
//         user-select: none;
//     }
//     .banner-section.style-one .banner-carousel .slide-item {
//         padding: 171px 0px 180px 0px;
//         min-height: 810px;
//     }
//     .banner-section.style-one .banner-carousel .slide-item {
//         position: relative;
//         overflow: hidden;
//     }
//     .banner-section.style-one .banner-carousel .slide-item.style-two .image-layer {
//         right: inherit;
//         left: 0;
//     }
    
//     .banner-section.style-one .banner-carousel .slide-item .image-layer {
//         position: absolute;
//         right: 0;
//         width: 43%;
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-position: center;
//     }
//     .banner-section .banner-carousel .slide-item .image-layer {
//         position: absolute;
//         top: 0;
//         height: 100%;
//         background-repeat: no-repeat;
//         background-position: center;
//         background-size: cover;
//         -webkit-transform: scale(1);
//         -ms-transform: scale(1);
//         transform: scale(1);
//         -webkit-transition: all 6000ms linear;
//         -moz-transition: all 6000ms linear;
//         -ms-transition: all 6000ms linear;
//         -o-transition: all 6000ms linear;
//         transition: all 6000ms linear;
//     }
    
// element.style {
// }
// .elementor *, .elementor :after, .elementor :before {
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }
// * {
//     margin: 0px;
//     padding: 0px;
//     border: none;
//     outline: none;
// }
// *, *::before, *::after {
//     box-sizing: border-box;
// }
// user agent stylesheet
// div {
//     display: block;
// }
// .owl-carousel .owl-item {
//     position: relative;
//     min-height: 1px;
//     float: left;
//     -webkit-backface-visibility: hidden;
//     -webkit-tap-highlight-color: transparent;
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }
// .owl-carousel {
//     display: none;
//     width: 100%;
//     -webkit-tap-highlight-color: transparent;
//     position: relative;
//     z-index: 1;
// }
// .banner-carousel .slide-item.style-two .pattern-layer .pattern-1 {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     width: 43%;
//     height: 100%;
//     background-repeat: no-repeat;
//     background-size: cover;
// }
// .banner-carousel .slide-item.style-two .pattern-layer .pattern-2 {
//     position: absolute;
//     right: 0px;
//     top: 0px;
//     width: 57%;
//     height: 100%;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .auto-container {
//     position: static;
//     max-width: 1200px;
//     padding: 0px 15px;
//     margin: 0 auto;
// }
// .banner-carousel .slide-item.style-two .content-box {
//     padding-left: 60px;
// }

// .banner-carousel .content-box {
//     position: relative;
//     display: block;
//     z-index: 5;
//     width: 100%;
// }
// .banner-carousel .content-box span {
//     color: #ff5956;
// }
// .banner-carousel .content-box span {
//     position: relative;
//     display: inline-block;
//     font-size: 15px;
//     line-height: 20px;
//     font-weight: 700;
//     margin-bottom: 13px;
//     text-transform: uppercase;
//     opacity: 0;
//     -webkit-transform: translateY(-100px);
//     -moz-transform: translateY(-100px);
//     -ms-transform: translateY(-100px);
//     -o-transform: translateY(-100px);
//     transform: translateY(-100px);
//     -webkit-transition: all 700ms ease;
//     -moz-transition: all 700ms ease;
//     -ms-transition: all 700ms ease;
//     -o-transition: all 700ms ease;
//     transition: all 700ms ease;
// }
// .banner-carousel .content-box h1 {
//     position: relative;
//     display: block;
//     font-size: 60px;
//     line-height: 68px;
//     font-weight: 800;
//     margin-bottom: 23px;
//     opacity: 0;
//     -webkit-transform: translateX(100px);
//     -moz-transform: translateX(100px);
//     -ms-transform: translateX(100px);
//     -o-transform: translateX(100px);
//     transform: translateX(100px);
//     -webkit-transition: all 1000ms ease;
//     -moz-transition: all 1000ms ease;
//     -ms-transition: all 1000ms ease;
//     -o-transition: all 1000ms ease;
//     transition: all 1000ms ease;
// }
// .banner-carousel .content-box p {
//     position: relative;
//     display: block;
//     color: #766068;
//     font-size: 18px;
//     line-height: 30px;
//     font-weight: 500;
//     margin-bottom: 41px;
//     opacity: 0;
//     -webkit-transform: translateX(-100px);
//     -moz-transform: translateX(-100px);
//     -ms-transform: translateX(-100px);
//     -o-transform: translateX(-100px);
//     transform: translateX(-100px);
//     -webkit-transition: all 1300ms ease;
//     -moz-transition: all 1300ms ease;
//     -ms-transition: all 1300ms ease;
//     -o-transition: all 1300ms ease;
//     transition: all 1300ms ease;
// }
// .banner-carousel .content-box .btn-box {
//     position: relative;
//     opacity: 0;
//     -webkit-transform: translateY(100px);
//     -moz-transform: translateY(100px);
//     -ms-transform: translateY(100px);
//     -o-transform: translateY(100px);
//     transform: translateY(100px);
//     -webkit-transition: all 1600ms ease;
//     -moz-transition: all 1600ms ease;
//     -ms-transition: all 1600ms ease;
//     -o-transition: all 1600ms ease;
//     transition: all 1600ms ease;
// }
// .theme-btn-two:after {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border-radius: 4px;
//     background: #462b34;
//     -webkit-transform: scaleX(0);
//     transform: scaleX(0);
//     -webkit-transform-origin: 50% 100%;
//     transform-origin: 50% 100%;
//     -webkit-transition-property: transform;
//     transition-property: transform;
//     -webkit-transition-duration: 0.5s;
//     transition-duration: 0.5s;
//     -webkit-transition-timing-function: ease-out;
//     transition-timing-function: ease-out;
// }
